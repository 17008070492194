import React from "react";
import serviceTwo1 from "@/images/what-we-do-1-1.jpg";
const ServiceTwo = () => {
  return (
    <section className="what-we-do sec-pad">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>Our Values</span>
          <h3>What we stand for</h3>
          <p>
            When we say we’re not happy until you’re happy, we mean it.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="single-what-we-do">
              <div className="img-box">
                <img src={serviceTwo1} alt="" />
              </div>
              <div className="text-box hvr-bounce-to-bottom">

                  <h3>Affordable</h3>

                <p>
                  When you work with Newby Enterprises, you are getting the best
                  value and support for all of your sign blank needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-sm-6">
                <div className="single-what-we-do-one hvr-bounce-to-bottom">
                  <i className="printify-icon-paper-drill"/>

                    <h3>High Quality</h3>

                  <p>
                    Our products are made specifically with durability and longevity
                    in mind. Made to last, rain or shine.
                  </p>


                </div>
              </div>
              <div className="col-sm-6">
                <div className="single-what-we-do-one hvr-bounce-to-bottom">
                  <i className="printify-icon-printing-text"/>
                    <h3>Dependable</h3>
                  <p>
                    We have been proudly serving our community and beyond since 1998.
                    You can always rely on us for the best service and best products.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceTwo;
