import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import AboutTwo from "@/components/about-two";
import ClientCarousel from "@/components/client-carousel";
import CallToActionThree from "@/components/call-to-action-three";
import ServiceTwo from "@/components/service-two";
import HeaderOne from "@/components/header-one";
import SliderOne from "@/components/slider-one";
import MenuContextProvider from "@/context/menu-context";
import ServiceTabTwo from "@/components/service-tab-2";
import CallToAction from "@/components/call-to-action";

const Home = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Home Page">
        <HeaderOne />
        <SliderOne />
        <ServiceTabTwo />
        <AboutTwo />
        <ServiceTwo />
        <CallToAction />
        <CallToActionThree />
        <ClientCarousel />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Home;
